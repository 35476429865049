<script>
    import Navbar from '$lib/components/navbar.svelte'
    import '../StyleBase.css'
    import ThemeMenu from '$lib/components/theme_menu.svelte'

    import {theme} from "$lib/theme"
    import { setContext } from 'svelte'
    setContext('theme', theme)

</script>

<div class="layout-grid grid">
    <Navbar>
        <h1
            class="flex-grow self-center text-center text-2xl font-semibold tracking-tight "
        >
            Word Entropy
        </h1>
        <div class="flex-none">
            <ul class="menu px-1 lg:menu-horizontal">
                <li>
                    <a class="flex-initial self-center" href="/login">Login</a>
                </li>
                <li>
                    <ThemeMenu />
                </li>
            </ul>
        </div>
    </Navbar>

    <slot />

    <footer class="m-4 rounded-lg bg-white shadow" />
</div>

<style>
    .layout-grid {
        grid-template-rows: 1fr 200vh 1fr;
    }
</style>

import '../StyleBase.css'
import { writable } from 'svelte/store'
import { browser } from '$app/environment'

// https://daisyui.com/docs/themes/

export const theme = writable(browser ? localStorage.getItem('theme') ?? 'system' : 'system')

theme.subscribe((value) => {
    if (!browser) {
        return
    }
    if (value === 'dark') {
        document.documentElement.setAttribute('data-theme', 'dark')
        localStorage.setItem('theme', 'dark')
    } else if (value === 'system') {
        localStorage.setItem('theme', 'system')
        window.matchMedia('(prefers-color-scheme: dark)').matches
            ? document.documentElement.setAttribute('data-theme', 'dark')
            : document.documentElement.setAttribute('data-theme', 'light')
    } else {
        localStorage.setItem('theme', 'light')
        document.documentElement.setAttribute('data-theme', 'light')
    }
})

if (browser) {
    // Watch for system preferences when we use system default
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (_) => {
        theme.update((value) => value)
    })
}


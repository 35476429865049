<!--
@component

# NavBar

Drop ready element, it will fill layout embed element when there is not scrolling, once the scrolling start a hidden
element no visible will be set in its place. It will become a sticky navbar.

It provides a flex layout 
-->

<script lang="ts">
    import { onMount } from 'svelte';

    let root: HTMLDivElement;
    let sticky = false;

    $: sticky_classes = sticky ? 'top-0 fixed' : '';
    $: offset = 0;

    function callback() {
        offset = root.offsetHeight;
        if (window.scrollY > 0) {
            sticky = true;
        } else {
            sticky = false;
        }
    }
    onMount(() => {
        offset = root.offsetHeight;
        window.addEventListener('scroll', callback);
        return () => {
            window.removeEventListener('scroll', callback);
        };
    });
</script>

<div>
    <div
        bind:this={root}
        class="navbar flex w-full justify-center bg-base-100 bg-opacity-70 shadow backdrop-blur-sm z-[100] {sticky_classes}"
    >
        <slot />
    </div>

    <div style="min-height: {offset}px;" class:hidden={!sticky} />
</div>

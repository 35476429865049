<script lang="ts">
    import { getContext } from 'svelte';
    import type { Writable } from 'svelte/store';
    import IoMdMoon from 'svelte-icons/io/IoMdMoon.svelte';
    import IoMdSettings from 'svelte-icons/io/IoMdSettings.svelte';
    import IoMdSunny from 'svelte-icons/io/IoMdSunny.svelte';
    import { browser } from '$app/environment';

    const theme: Writable<string> = getContext('theme');

    let menu: HTMLDetailsElement;

    if (browser) {
        window.addEventListener('click', function (e) {
            if (menu && !menu.contains(e.target as Node)) {
                menu.open = false;
            }
        });
    }

    let system_theme = 'light';

    if (browser) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            system_theme = 'dark';
        }
        // Watch for system preferences when we use system default
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            if (e.matches) {
                system_theme = 'dark';
            } else {
                system_theme = 'light';
            }
        });
    }
</script>

<details bind:this={menu}>
    <summary>
        <div class="h-5 w-5">
            {#if $theme === 'dark' || ($theme === 'system' && system_theme === 'dark')}
                <IoMdMoon />
            {:else}
                <IoMdSunny />
            {/if}
        </div>
    </summary>
    <ul class="p-2 bg-base-100 right-0">
        <li>
            <button on:click={() => theme.set('light')}>
                <div class="h-5 w-5">
                    <IoMdSunny />
                </div>
                Light
            </button>
        </li>
        <li>
            <button on:click={() => theme.set('dark')}>
                <div class="h-5 w-5">
                    <IoMdMoon />
                </div>
               	Dark
            </button>
        </li>
        <li>
            <button on:click={() => theme.set('system')}>
                <div class="h-5 w-5">
                    <IoMdSettings />
                </div>
                System
            </button>
        </li>
    </ul>
</details>
